import { AppProps } from 'next/app';
import React from 'react';
import { fetchAPI } from '@/lib/api';
import Head from 'next/head';
import dynamic from 'next/dynamic';

const ComponentParser = dynamic(() => import('@/components/DynamicComponent'));

interface HomeProps extends AppProps {
  pageProps: {
    id: number;
    attributes: {
      components: {
        __component: string;
        [key: string]: unknown;
      }[];
      pageTitle: string;
      pageDescription: string;
    };
  };
}

function Home(props: HomeProps) {
  const { pageProps } = props;

  return (
    <div key={pageProps?.id}>
      <Head>
        {pageProps?.attributes?.pageTitle && (
          <title>{pageProps.attributes.pageTitle}</title>
        )}
        {pageProps?.attributes?.pageTitle && (
          <meta
            property="og:title"
            content={pageProps.attributes.pageTitle}
            key="title"
          />
        )}
        {/* {pageProps?.attributes?.pageDescription && (
          <meta
            name="description"
            content={pageProps.attributes.pageDescription}
          />
        )} */}
      </Head>
      {pageProps?.attributes?.components &&
        pageProps.attributes.components.map((component) => {
          return (
            // eslint-disable-next-line no-underscore-dangle
            <div key={`${component.__component} - ${component.id as number}`}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <ComponentParser {...component} />
            </div>
          );
        })}
    </div>
  );
}

export async function getStaticProps() {
  const homepageRes = await fetchAPI('/homepage');

  return {
    props: { data: homepageRes.data },
  };
}

export default Home;
